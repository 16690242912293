<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>赛事列表</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="matchArea">
            <el-table :data="matchList" stripe highlight textcenter style="width: 100%">
                <el-table-column prop="matchLogo" label="赛事logo" align="center">
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.matchLogo"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="matchTitle" label="赛事名称" align="center"></el-table-column>
                <el-table-column prop="teamCount" label="球队数量" align="center"></el-table-column>
                <el-table-column prop="playerCount" label="球员数量" align="center"></el-table-column>
                <el-table-column label="操作" align="center" style="width: 100%; white-space: nowrap">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="matchDetail(scope.row)">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 新增赛事按钮 -->
        <el-button type="primary" class="add-btn" icon="el-icon-plus" title="新增赛事" @click="addMatch"></el-button>
        <el-dialog :visible.sync="dialogVisible" :title="title" width="60%" @close="closeDialog">
            <div class="overviewCommand_detail">
                <div class="step-com">
                    <el-steps :active="currentStep" align-center>
                        <el-step v-for="item of steps" :key="item" :title="item"></el-step>
                    </el-steps>
                </div>
                <!-- 第一步 -->
                <div class="step-one" v-if="currentStep === 1">
                    <el-form label-width="240px" ref="stepOneForm" :model="stepOneForm" :rules="rules">
                        <el-form-item v-for="item of stepOneColumns" :key="item.title" :label="item.title"
                            :prop="item.code">
                            <template v-if="item.type === 1">
                                <div style="display: flex;">
                                    <el-input v-model="stepOneForm[item.code]" :disabled="!item.editable" class="wpct30"
                                        placeholder="请输入"></el-input>
                                    <el-button type="primary" v-if="item.code === 'stadiumAddress'"
                                        style="margin-left: 10px" @click="showMap">地图选点</el-button>
                                </div>
                            </template>
                            <template v-if="item.type === 2">
                                <el-cascader v-model="stepOneForm[item.code]" :options="province.city" clearable></el-cascader>
                            </template>
                            <template v-if="item.type === 3">
                                <f-image-upload v-model="stepOneForm[item.code]" :width="'150px'"
                                    :height="'150px'"></f-image-upload>
                            </template>
                        </el-form-item>
                    </el-form>
                </div>
                <!-- 第二步 -->
                <div class="step-two" v-if="currentStep === 2">
                    <el-form label-width="240px" ref="stepTwoForm" :model="stepTwoForm" :rules="rules">
                        <el-form-item v-for="item of stepTwoColumns" :key="item.title" :label="item.title"
                            :prop="item.code">
                            <template v-if="item.type === 1">
                                <el-select v-model="stepTwoForm[item.code]" class="wpct30">
                                    <el-option v-for="ops in item.propertyOptionList" :key="ops.value" :label="ops.label"
                                        :value="ops.value">
                                    </el-option>
                                </el-select>
                            </template>
                            <template v-if="item.type === 4">
                                <el-date-picker class="wpct30" v-model="stepTwoForm[item.code]" type="date"
                                    placeholder="请选择" value-format="yyyy-MM-dd"></el-date-picker>
                            </template>
                        </el-form-item>
                    </el-form>
                </div>
                <!-- 第三步 -->
                <div class="step-three" v-if="currentStep === 3">
                    <el-form label-width="240px" ref="stepThreeForm" :model="stepThreeForm" :rules="rules">
                        <el-form-item v-for="item of stepThreeColumns" :key="item.title" :label="item.title"
                            :prop="item.code">
                            <template v-if="item.type === 1">
                                <el-input v-model="stepThreeForm[item.code]" class="wpct30" placeholder="请输入"><i
                                        slot="suffix">{{ item.suffix }}</i></el-input>
                            </template>
                            <template v-if="item.type === 2">
                                <el-select v-model="stepThreeForm[item.code]" class="wpct30">
                                    <el-option v-for="ops in item.propertyOptionList" :key="ops.value" :label="ops.label"
                                        :value="ops.value">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-form-item>
                    </el-form>
                </div>
                <!-- 第四步 -->
                <div class="step-three" v-if="currentStep === 4">
                    <el-form label-width="240px" ref="stepFourForm" :model="stepFourForm" :rules="rules">
                        <el-form-item v-for="item of stepFourColumns" :key="item.title" :label="item.title"
                            :prop="item.code">
                            <template v-if="item.type === 1">
                                <el-input v-model="stepFourForm[item.code]" class="wpct30" placeholder="请输入"></el-input>
                            </template>
                            <template v-if="item.type === 2">
                                <el-select v-model="stepFourForm[item.code]" class="wpct30">
                                    <el-option v-for="ops in item.propertyOptionList" :key="ops.value" :label="ops.label"
                                        :value="ops.value">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-form-item>
                        <div v-if="this.stepFourForm.entryType == 0">
                            <el-form-item label="报名费:">
                                <el-input class="inputPrice" placeholder="请输入报名费" v-model="stepFourForm.entryFee"
                                    type="number" style="width: 30%;">
                                    <i slot="suffix" class="dw">￥/队</i></el-input>
                                <div class="promptText">* 报名费将于报名截止日可发起结算</div>
                            </el-form-item>
                            <el-form-item label="保证金:">
                                <el-input class="inputPrice" placeholder="请输入保证金" v-model="stepFourForm.earnestMoney"
                                    type="number" style="width: 30%;">
                                    <i slot="suffix" class="dw">￥/队</i></el-input>
                                <div class="promptText">* 保证金需赛事方在比赛结束后线下结算给球队方</div>
                            </el-form-item>
                            <el-form-item label="最少报名球队:">
                                <el-input class="inputMin" v-model="stepFourForm.minSignTeam" style="width: 30%;">
                                    <i slot="suffix" class="dw">/支</i></el-input>
                            </el-form-item>
                            <el-form-item label="最多报名球队:">
                                <el-input class="inputMax" v-model="stepFourForm.maxSignTeam" style="width: 30%;">
                                    <i slot="suffix" class="dw">/支</i></el-input>
                            </el-form-item>
                        </div>
                        <!-- <div v-if="this.stepFourForm.entryType == 0">
                            <el-form-item label="邀请码">
                                <el-input class="inputPrice" placeholder="请输入邀请码" v-model="form.applicationPrice"
                                    type="number" style="width: 30%;">
                                </el-input>
                                <div class="promptText">* 请输入8位数赛事编码</div>
                            </el-form-item>
                        </div> -->
                    </el-form>
                </div>
                <div class="btn-com">
                    <el-button type="primary" @click="currentStep -= 1" v-if="currentStep !== 1">上一步</el-button>
                    <el-button type="primary" v-if="currentStep !== steps.length" @click="next">下一步</el-button>
                    <el-button type="primary" class="save-btn" v-if="currentStep === steps.length" @click="handleSave">保存
                    </el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 地图弹窗 -->
        <el-dialog :visible.sync="showMapDialog" v-if="showMapDialog" title="地图选点">
            <Map @map-submit="mapSubmit" :address="stepOneForm.stadiumAddress"></Map>
        </el-dialog>
    </div>
</template> 
<script>
import Map from '@/views/eventManage/components/Map';
import province from "@/apidata/province.json";
import FImageUpload from '@/common/components/Upload/f-image-upload';
export default {
    components: { Map, FImageUpload },
    data() {
        return {
            matchList: [],
            title: '',
            dialogVisible: false,
            currentStep: 1,
            steps: ['基本信息', '赛程时间', '赛事规模', '赛制信息'],
            showMapDialog: false,
            rules: {
                matchLogo: [{ required: true, message: '请上传', trigger: 'change' }],
                matchTitle: [{ required: true, message: '请输入', trigger: 'blur' }],
                organizer: [{ required: true, message: '请输入', trigger: 'blur' }],
                city: [{ required: true, message: '请输入', trigger: 'change' }],
                stadium: [{ required: true, message: '请输入', trigger: 'blur' }],
                stadiumAddress: [{ required: true, message: '请输入', trigger: 'blur' }],
                beginDate: [{ required: true, message: '请选择', trigger: 'change' }],
                endDate: [{ required: true, message: '请选择', trigger: 'change' }],
                signBeginDate: [{ required: true, message: '请选择', trigger: 'change' }],
                signEndDate: [{ required: true, message: '请选择', trigger: 'change' }],

                matchType: [{ required: true, message: '请选择', trigger: 'change' }],
                teamGroupCount: [{ required: true, message: '请输入', trigger: 'blur' }],
                teamPlayerCount: [{ required: true, message: '请输入', trigger: 'blur' }],
                totalGame: [{ required: true, message: '请输入', trigger: 'blur' }],


                matchGroup: [{ required: true, message: '请选择', trigger: 'change' }],
                entryType: [{ required: true, message: '请选择', trigger: 'change' }],
                entryFee: [{ required: true, message: '请输入', trigger: 'change' }],
                contact: [
                    { required: true, message: '请输入电话号码', trigger: 'blur' },
                    { pattern: /^[1][3,4,5,7,8][0-9]{9}$/, message: '请输入正确的手机号码', trigger: 'blur', }],
            },
            stepOneForm: {
                matchLogo: '',
                matchTitle: '',
                organizer: '',
                city: '',
                stadium: '',
                stadiumAddress: ''
            },
            stepOneColumns: [
                { title: '赛事logo（建议1:1上传）：', type: 3, code: 'matchLogo', editable: true },
                { title: '赛事名称：', type: 1, code: 'matchTitle', editable: true },
                { title: '主办单位：', type: 1, code: 'organizer', editable: true },
                { title: '举办城市：', type: 2, code: 'city', editable: true },
                { title: '承办球场：', type: 1, code: 'stadium', editable: true },
                { title: '球场地址：', type: 1, code: 'stadiumAddress', editable: false },
            ],

            stepTwoForm: {
                matchType: '',
                beginDate: '',
                endDate: '',
                signBeginDate: '',
                signEndDate: '',
            },
            stepTwoColumns: [
                {
                    title: '赛事类型：',
                    type: 1,
                    code: 'matchType',
                    propertyOptionList: [
                        {
                            value: 1,
                            label: '公开赛',
                        },
                        {
                            value: 2,
                            label: '官方赛',
                        },
                        {
                            value: 3,
                            label: '校园赛',
                        },
                        {
                            value: 4,
                            label: '城市杯',
                        },
                        {
                            value: 5,
                            label: '球场联赛',
                        },
                    ],
                },
                { title: '赛事开始日：', type: 4, code: 'beginDate' },
                { title: '赛事结束日：', type: 4, code: 'endDate' },
                { title: '报名开始日：', type: 4, code: 'signBeginDate' },
                { title: '报名结束日：', type: 4, code: 'signEndDate' },
            ],

            stepThreeForm: {
                matchFormat: '',
                matchType: '',
                matchGroup: '',
                teamGroupCount: '',
                teamPlayerCount: '',
                totalGame: '',
            },
            stepThreeColumns: [
                {
                    title: '赛制：',
                    type: 2,
                    code: 'matchFormat',
                    propertyOptionList: [
                        {
                            value: 0,
                            label: '联赛-单循环',
                        },
                        {
                            value: 1,
                            label: '联赛-双循环',
                        },
                        {
                            value: 2,
                            label: '杯赛-小组赛+淘汰赛',
                        },
                        {
                            value: 3,
                            label: '杯赛-淘汰赛',
                        },
                    ],
                },
                {
                    title: '场制：',
                    type: 2,
                    code: 'matchType',
                    propertyOptionList: [
                        {
                            value: 5,
                            label: '五人制',
                        },
                        {
                            value: 6,
                            label: '六人制',
                        },
                        {
                            value: 7,
                            label: '七人制',
                        },
                        {
                            value: 8,
                            label: '八人制',
                        },
                        {
                            value: 9,
                            label: '九人制',
                        },
                        {
                            value: 10,
                            label: '十人制',
                        },
                        {
                            value: 11,
                            label: '十一人制',
                        },
                    ],
                },
                {
                    title: '组别:',
                    type: 2,
                    code: 'matchGroup',
                    propertyOptionList: [
                        {
                            value: 1,
                            label: '成人组',
                        },
                        {
                            value: 2,
                            label: 'U8组',
                        },
                        {
                            value: 3,
                            label: 'U12组',
                        },
                        {
                            value: 4,
                            label: 'U15组',
                        },
                        {
                            value: 5,
                            label: '4个分组',
                        },
                    ],
                },
                { title: '球队分组数量：', type: 1, code: 'teamGroupCount', suffix: '/支' },
                { title: '球员分组数量:', type: 1, code: 'teamPlayerCount', suffix: '/人' },
                { title: '比赛场数：', type: 1, code: 'totalGame', suffix: '/场' },
            ],

            stepFourForm: {
                contact: '',
                entryType: '',
                entryFee: 0,
                earnestMoney: 0,
                minSignTeam: 0,
                maxSignTeam: 0
            },
            stepFourColumns: [
                { title: '联系方式', type: 1, code: 'contact', editable: true },
                {
                    title: '报名方式：',
                    type: 2,
                    code: 'entryType',
                    propertyOptionList: [
                        {
                            value: 0,
                            label: '自费制',
                        },
                        {
                            value: 1,
                            label: '邀请制',
                        },
                    ],
                },
                // { title: '报名费', type: 3, code: 'entryFee', suffix: '￥/队' },
                // { title: '保证金', type: 3, code: 'earnestMoney ', suffix: '￥/队' },
                // { title: '最少报名球队', type: 3, code: 'minSignTeam', suffix: '/支' },
                // { title: '最多报名球队', type: 3, code: 'maxSignTeam', suffix: '/支' },
            ],
            matchData: {
                infoRequest: {},
                extInfoRequest: {},
                formatInfoReqeust: {}
            },
            province:province
        }
    },
    mounted() {
        this.initmatchList();
    },
    methods: {
        // 获取赛事列表
        async initmatchList() {
            const { res } = await this.$http.get('/Match/GetMatchPageList');
            this.matchList = res.data || [];
        },
        // 下一步
        next() {
            if (this.currentStep === 1) {
                this.$refs.stepOneForm.validate((valid) => {
                    if (valid) {
                        this.currentStep += 1;
                    }
                });
                return;
            }
            if (this.currentStep === 2) {
                this.$refs.stepTwoForm.validate((valid) => {
                    if (valid) {
                        this.currentStep += 1;
                    }
                });
                return;
            }
            if (this.currentStep === 3) {
                this.$refs.stepThreeForm.validate((valid) => {
                    if (valid) {
                        this.currentStep += 1;
                    }
                });
                return;
            }
        },
        // 新增赛事
        addMatch() {
            this.dialogVisible = true
        },
        // 保存方法，第四步校验成功后，拿到所有表单的信息一起保存
        handleSave() {
            this.$refs.stepFourForm.validate((valid) => {
                if (valid) {
                    this.stepOneForm.city=this.stepOneForm.city[1]
                    this.matchData.infoRequest = Object.assign(this.stepOneForm, this.stepTwoForm)
                    this.matchData.formatInfoReqeust = this.stepThreeForm
                    this.matchData.extInfoRequest = this.stepFourForm
                    this.$confirm('请核实信息，确定要发布改赛事吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(async () => {
                        const { res } = await this.$http.post('/Match/SaveMatch', this.matchData);
                        if (res.isSuccess) {
                            this.$showSuccess('发布成功');
                            this.dialogVisible = false
                            this.initmatchList();
                        }
                    });
                } else {
                    this.$message.error("请填写完整信息！");
                    return
                }
            });
        },
        // 关闭弹窗
        closeDialog() {
            this.dialogVisible = false;
            this.stepFourForm = {};
            this.stepThreeForm = {};
            this.stepTwoForm = {};
            this.stepOneForm = {};
        },
        // 赛事详情
        matchDetail(row) {
            this.$router.push({
                name: 'matchDetail',
                query: {
                    matchId: row.matchId,
                    matchTitle: row.matchTitle,
                    matchLogo: row.matchLogo,
                },
            });
        },
        // 地图弹窗
        showMap() {
            this.showMapDialog = true;
        },
        // 选择地址
        mapSubmit(location, address) {
            console.log(location, address);
            this.stepOneForm.stadiumAddress = address;
            this.stepOneForm.longitude = location.lng;
            this.stepOneForm.latitude = location.lat;
            this.showMapDialog = false;
        },
    },

};
</script>

<style lang="scss" scoped>
.matchArea {
    margin-top: 30px;
}

.add-btn {
    position: absolute;
    right: 50px;
    bottom: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.promptText {
    width: 90%;
    color: #134f7a;
    opacity: 0.6;
}
</style>
